<template>
  <div id="mixandmatchpricing">
    <v-data-table
      :headers="header"
      :items="mixAndMatchData"
      :options.sync="options"
      :items-per-page="options.itemsPerPage"
      :loading="loading"
      :server-items-length="itemsLength"
      :height="530"
      class="elevation-1 mb-5"
      hide-default-footer
      @update:page="optionsUpdated()"
      @update:sort-by="optionsUpdated()"
      @update:sort-desc="optionsUpdated()">
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>
            <span class="capitalize">
              Mix & Match Pricing
            </span>
          </v-toolbar-title>
          <v-divider
            class="mx-4"
            inset
            vertical />
          <v-spacer />
          <search-box
            v-model="search"
            @on-search="searchMixAndMatch($event)" />
          <v-dialog
            v-model="dialog"
            max-width="500px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="mb-1 ml-2"
                color="primary"
                dark
                v-bind="attrs"
                v-on="on">
                New Item
              </v-btn>
            </template>
            <v-card>
              <validation-observer
                ref="mixAndMatchForm"
                tag="form"
                @submit.prevent="save()">
                <v-card-title>
                  <span class="headline">{{ formTitle }}</span>
                </v-card-title>
                <v-card-text>
                  <v-container>
                    <v-row v-if="dialog">
                      <v-col
                        v-for="(form, key) in formData"
                        :key="`formData-${key}`"
                        :cols="12">
                        <gw-text-field
                          v-model="formData[key]"
                          :rules="textFieldRules"
                          :label="capitalize(key)"
                          :readonly="modalLoading"
                          number />
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer />
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="closeFormModal()">
                    Cancel
                  </v-btn>
                  <v-btn
                    :loading="modalLoading"
                    color="blue darken-1"
                    type="submit"
                    text>
                    Save
                  </v-btn>
                </v-card-actions>
              </validation-observer>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:[`item.tier1`]="{ value }">
        <p>
          {{ value | showFullPriceFormat() }}
        </p>
      </template>
      <template v-slot:[`item.tier3`]="{ value }">
        <p>
          {{ value | showFullPriceFormat() }}
        </p>
      </template>
      <template v-slot:[`item.tier5`]="{ value }">
        <p>
          {{ value | showFullPriceFormat() }}
        </p>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon
          small
          class="mr-2"
          @click="editItem(item)">
          mdi-pencil
        </v-icon>
        <gw-icon-hover
          small
          class="sku-table-bin"
          icon-name="mdi-delete"
          icon-hover="mdi-delete-empty"
          @click="removeAttribute(item)" />
      </template>
    </v-data-table>
    <v-pagination
      v-if="paginationEnable"
      v-model="options.page"
      :length="pageCount"
      :disabled="loading"
      total-visible="7" />
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import PromotionProvider from '@/resources/PromotionProvider'
import SearchBox from '@/components/SearchBox.vue'

const PromotionService = new PromotionProvider()

export default {
  components: { SearchBox },
  props: {
    attributeName: {
      type: String,
      requied: true,
      default: ''
    }
  },
  data () {
    return {
      dialog: false,
      loading: false,
      itemsLength: 0,
      pageCount: 0,
      options: {
        page: 1,
        itemsPerPage: 10,
        sortBy: ['tier1'],
        sortDesc: [true]
      },
      header: [
        {
          text: 'Tier1',
          value: 'tier1',
          width: 150,
          align: 'center'
        },
        {
          text: 'Tier3',
          value: 'tier3',
          width: 150,
          align: 'center'
        },
        {
          text: 'Tier5',
          value: 'tier5',
          width: 150,
          align: 'center'
        },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false,
          width: 60,
          align: 'end'
        }
      ],
      mixAndMatchData: [],
      isEdited: false,
      formData: {
        tier1: null,
        tier3: null,
        tier5: null
      },
      textFieldRules: {
        required: true,
        regex: /^[+-]?([0-9]*[.])?[0-9]+$/
      },
      editingId: null,
      modalLoading: false,
      search: ''
    }
  },
  computed: {
    formTitle () {
      return this.isEdited ? 'Edit Item' : 'New Item'
    },
    paginationEnable () {
      return this.pageCount > 1
    }
  },
  mounted () {
    if (this.search === '') {
      this.search = this.$route.query?.search || ''
    }
    this.getMixAndMatch(this.options)
  },
  methods: {
    ...mapActions({
      setSnackbar: 'Components/setSnackbar',
      setErrorPage: 'Components/setErrorPage',
      setModal: 'Components/setModal'
    }),
    capitalize (value) {
      if (value) {
        return value.toString().charAt(0).toUpperCase() + value.slice(1)
      }
      return ''
    },
    searchMixAndMatch (value) {
      this.options.page = 1
      this.search = value

      this.$router.push({
        name: this.$route.name,
        query: { search: value }
      }).catch(() => {})
      this.getMixAndMatch(this.options)
    },
    async getMixAndMatch (options) {
      try {
        this.loading = true
        const { data } = await PromotionService.getMixAndMacthPricing({
          ...options,
          search: this.search
        })
        this.itemsLength = data.total
        this.pageCount = data.pages
        this.mixAndMatchData = data.results
      } catch (error) {
        console.error('getMixAndMatch: ', error)
        this.setErrorPage(error.code)
      } finally {
        this.loading = false
      }
    },
    optionsUpdated () {
      this.getMixAndMatch(this.options)
    },
    closeFormModal () {
      this.dialog = false
      this.isEdited = false
      this.editingId = null
      this.modalLoading = false
      this.formData = {
        tier1: null,
        tier3: null,
        tier5: null
      }
    },
    editItem (item) {
      this.formData = {
        tier1: item.tier1,
        tier3: item.tier3,
        tier5: item.tier5
      }
      this.editingId = item.id
      this.isEdited = true
      this.dialog = true
    },
    async save () {
      this.modalLoading = true
      const vaid = await this.$refs.mixAndMatchForm.validate()
      if (vaid) {
        let isSuscess = false
        let message = 'created'
        try {
          this.modalLoading = true
          if (this.isEdited) {
            isSuscess = await this.updateMixAndMacthPricing(this.formData)
            message = 'updated'
          } else {
            isSuscess = await this.createMixAndMacthPricing(this.formData)
          }
        } catch (error) {
          this.setSnackbar({
            value: true,
            message: `Error code ${error.code} : ${error.message}`,
            type: 'error'
          })
          console.error('saveMixAndMacthPricing', error)
        } finally {
          this.closeFormModal()
          if (isSuscess) {
            this.setSnackbar({
              value: true,
              message: this.$t(`snackbar.MixAndMatch ${message}`),
              type: 'success'
            })
            this.getMixAndMatch(this.options)
          }
        }
      }
      this.modalLoading = false
    },
    async createMixAndMacthPricing (items) {
      const { data } = await PromotionService.createMixAndMacthPricing(items)
      return !!data.id
    },
    async updateMixAndMacthPricing (item) {
      const { data } = await PromotionService.updateMixAndMacthPricing(this.editingId, item)
      return !!data.id
    },
    async removeAttribute (item) {
      this.setModal({
        value: true,
        title: 'RemoveMixAndMatch',
        message: 'Do you want to remove the Mix And Match pricing',
        confirmText: 'Remove',
        confirmType: 'error',
        cancelType: '',
        cancelText: 'Cancel',
        onConfirm: () => this.confirmRemoveMixAndMacthPricing(item.id)
      })
    },
    async confirmRemoveMixAndMacthPricing (id) {
      try {
        const { data } = await PromotionService.deleteMixAndMacthPricing(id)
        if (data.id && data.status === 'deleted') {
          this.setSnackbar({
            value: true,
            message: this.$t('snackbar.MixAndMatch removed'),
            type: 'success'
          })
          if (this.options.page > 1 && this.mixAndMatchData.length < 2) {
            this.options.page--
          } else {
            this.getMixAndMatch(this.options)
          }
        } else {
          const error = {
            code: 400,
            message: 'Mix And Match ID not found or status not change'
          }
          throw error
        }
      } catch (error) {
        this.setSnackbar({
          value: true,
          message: `Error code ${error.code}`,
          type: 'error'
        })
        console.error('confirmRemoveMixAndMatch', error)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
#mixandmatchpricing .v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
#mixandmatchpricing .v-data-table > .v-data-table__wrapper > table > thead > tr > th {
  border: thin solid rgba(0, 0, 0, 0.12);
}
</style>
